import React from "react"
import {BREAKPOINTS} from "src/utils/exportFunctions"

const StylesSubpageMv= (props) => {
    return (
        <style>{`
        .subPageMv .wrapImg::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #7B7B7B;
            mix-blend-mode: multiply;
            z-index: 5;
        }

        .subPageMv .anchorList li a::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
            right: 1.75rem;
            width: 10px;
            height: 10px;
            border-bottom: solid 1px #FFF;
            border-right: solid 1px #FFF;
        }
        @media (min-width: ${BREAKPOINTS['md']}px) {
 
        }
        @media (min-width: ${BREAKPOINTS['lg']}px) {
        }
        @media (min-width: ${BREAKPOINTS['xl']}px) {
            .subPageMv .wrapImg::before {
                content: none;
            }
            .subPageMv .anchorList li a::after {
                border-bottom: solid 1px #000;
                border-right: solid 1px #000;
                right: 1rem;
            }
        }
        `}
        </style>
    );
};

export default StylesSubpageMv;