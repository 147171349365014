import * as React from "react"
import { AnimUp } from "src/components/animation.js"

const SubPageAccessMapBlock = (props) => {
    return (
        <div className={props.class} style={props.style}>
            <h2 className="
                 font-thin tracking-35em leading-19px mb-6
                 md:leading-42px md:mb-12
                 xl:font-light xl:mb-14
            ">
                <AnimUp>
                    <p className="
                        text-13px pl-35em
                        md:text-20px
                    ">ライオン企画株式会社</p>
                </AnimUp>
                <AnimUp>
                    <p className="
                        text-17px pl-35em
                        md:text-24px
                    ">{props.name}</p>
                </AnimUp>
            </h2>
            <AnimUp>
                <iframe src={props.gmap} title={props.name} allowFullScreen="" loading="lazy" className="
                    gmap w-full mb-8
                    md:mb-13
                "/>
            </AnimUp>
            <div className="
                font-thin tracking-35em
                xl:font-light
            ">
                <AnimUp>
                    <p className="
                        text-15px leading-23px pl-35em
                        md:text-19px md:leading-42px md:mb-3
                    ">
                        <span className="
                            lg:mr-4
                        ">{props.zip}</span>
                        <br className="lg:hidden" />
                        <span>{props.address}</span>
                    </p>
                </AnimUp>
                <AnimUp>
                    <p className="
                        text-13px leading-21px pl-35em
                        md:text-19px md:leading-42px
                    ">
                        <span>Tel ：{props.tel}</span>
                        <span className="
                            lg:mx-2
                        ">／</span>
                        <br className="lg:hidden" />
                        <span>Fax ：{props.fax}</span>
                    </p>
                </AnimUp>
            </div>
        </div>
    );
};

export default SubPageAccessMapBlock;