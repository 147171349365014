import React from "react"
import { BREAKPOINTS } from "src/utils/exportFunctions"

const StylesSubPageAccessMapBlock = (props) => {
    return (
        <style>{`
        .SubPageAccessMapBlock .gmap {
            height: 165px;
        }
        @media (min-width: ${BREAKPOINTS['md']}px) {
            .SubPageAccessMapBlock .gmap {
                height: 460px;
            }
        }
        `}</style>
    );
};

export default StylesSubPageAccessMapBlock;