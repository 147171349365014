import React from "react"
import Image from "src/components/image"
import TitleSvg from "src/components/titleSvg";
import StylesTitleSvg from "src/components/styles/titleSvg";
import { getJpTitle, getPagesName } from "src/utils/exportFunctions"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { AnimLeft, AnimIn } from "src/components/animation.js"

const SubPageMv = (props) => {
    const pageName = getPagesName(props.pageId);
    let anchorHtml = [];
    let anchorDelay = 700;
    for (var i in props.anchorList) {
        /*
        anchorHtml.push(<li className="
            w-full text-13px tracking-35em leading-none  border border-white text-white mb-12
            md:w-2/5 md:text-16px
            xl:w-1/2 xl:text-22px xl:tracking-25em xl:border-none xl:text-black xl:bg-links-anchor
        " key={i}>
            <a className="
                block p-7 relative
            " href={'#' + props.anchorList[i].anchorId}>{props.anchorList[i].text}</a>
        </li>);
        */
        let xlMargin = 'md:ml-8';
        if ((i % 2) === 0) {
            xlMargin = 'md:mr-8';
        }
        anchorDelay += 100;
        anchorHtml.push(<li className="
            w-full 
            md:w-1/2
            xl:w-1/2 
        " key={i}>  
            <AnimLeft delay={anchorDelay}>
                <AnchorLink className={`
                    block p-7 relative text-13px tracking-35em leading-none  border border-white text-white mb-12
                    md:text-16px
                    xl:p-4 xl:text-22px xl:font-light xl:tracking-25em xl:border-none xl:text-black xl:bg-links-anchor
                    ${xlMargin}
                `} to={'/' + pageName + '#' + props.anchorList[i].anchorId}>{props.anchorList[i].text}</AnchorLink>
            </AnimLeft>
        </li>);
    }
    const mvId = 'subPageMv_' + pageName;
    return (
        <div id={mvId} className="
            subPageMv
            pt-16 pb-12
            md:pt-20 md:pb-28
            xl:pt-40 xl:pb-20
        ">
            <h1 className="
                w-sm mx-auto max-w-1000px
                md:w-md
                xl:w-xl
            ">
                <StylesTitleSvg />
                <AnimLeft margin="-10%">
                    <TitleSvg {...props} />
                </AnimLeft>
                <AnimLeft delay="500" margin="-10%">
                    <p className="
                        text-18px tracking-35em leading-none py-6
                        md:text-25px md:py-12
                        xl:text-35px xl:py-9
                    ">{getJpTitle(props.pageId)}</p>
                </AnimLeft>
            </h1>
            <AnimIn delay="700">
                <div className="
                    wrapImg
                    relative w-full
                ">
                    <div className="
                        w-sm max-w-1000px absolute transform -translate-y-1/2 -translate-x-1/2 top-1/2 left-1/2 z-10
                        md:w-md
                        xl:w-xl xl:relative  xl:mx-auto xl:transform-none xl:top-0 xl:left-0 xl:mb-16
                    ">
                        <ul className="
                            anchorList
                            flex flex-wrap flex-row justify-between
                        ">{anchorHtml}</ul>
                    </div>
                    <Image
                        isUsePicture={true}
                        ext={"jpg"}
                        filename={pageName + '_mv'}
                        alt={pageName}
                        sizes={{
                            'width': {'sp': 641, 'tab': 1537, 'pc': 1600},
                            'height': {'sp': 677, 'tab': 615, 'pc': 416}
                        }}
                    />
                </div>
            </AnimIn>
        </div>
    );
};

export default SubPageMv;