import * as React from "react"
import Layout from "src/components/layout"
import Meta from "src/components/meta"
import { PAGE_IDS } from "src/utils/exportFunctions"
import SubPageMv from "src/components/subpage/mv"
import SubPageAccessMapBlock from "src/components/subpage/access/mapBlock"
import StylesSubpageMv from "src/components/styles/subpage/mv"
import StylesSubPageAccessMapBlock from "src/components/styles/subpage/access/mapBlock"

const AccessPage = () => {
    const anchorList = [
        { 'text': '東京本社', 'anchorId': 'section_tokyo' },
        { 'text': '大阪支社', 'anchorId': 'section_oosaka' },
    ];

    return (
        <Layout>
            <Meta
                title="アクセス"
                desc="ライオン企画のアクセスページです"
            />
            <StylesSubpageMv />
            <SubPageMv pageId={PAGE_IDS['access']} anchorList={anchorList} />
            <StylesSubPageAccessMapBlock />
            <section id="section_tokyo" className="
                w-sm mx-auto mb-24
                md:w-md md:mb-56
                xl:w-xl xl:max-w-1000px xl:mb-40
            ">
                <div className="SubPageAccessMapBlock">
                    <SubPageAccessMapBlock
                        class='text-center'
                        name='東京本社'
                        zip='〒161-8520'
                        address='東京都新宿区中落合1-6-12'
                        gmap='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d51830.27441805837!2d139.65510607910156!3d35.716573000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f2b33ea75dbd%3A0x1a1593f5eb549151!2z44Op44Kk44Kq44Oz5LyB55S777yI5qCq77yJ!5e0!3m2!1sja!2sjp!4v1629440686578!5m2!1sja!2sjp'
                        tel='03-5982-2361'
                        fax='03-5982-2371'
                    />
                </div>
            </section>
            <section id="section_oosaka" className="
                w-sm mx-auto mb-20
                md:w-md md:mb-32
                xl:w-xl xl:max-w-1000px xl:mb-36
            ">
                <div className="SubPageAccessMapBlock">
                    <SubPageAccessMapBlock
                        class='text-center'
                        name='大阪支社'
                        zip='〒531-0071'
                        address='大阪府大阪市北区中津1-2-20 新清風中津ビル 3階'
                        gmap='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3279.819972635511!2d135.49328391156922!3d34.70971798261553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e768d647d13b%3A0x6b315943f84c1d73!2z5paw5riF6aKo5Lit5rSl44OT44Or!5e0!3m2!1sja!2sjp!4v1696302081990!5m2!1sja!2sjp'
                        tel='06-6374-4581'
                        fax='06-6374-4582'
                    />
                </div>
            </section>
        </Layout>
    )
}

export default AccessPage
